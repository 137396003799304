@import "main-bx";

.checkbox-block {
    cursor: pointer;

    &__input {
        display: none;

        &:checked {
            &+.checkbox-block__label {
                &::after {
                    opacity: 1;
                }

                &::before {
                    background-color: $main_color;
                }
            }
        }
    }

    &__label {
        position: relative;
        display: flex;
        align-items: flex-start;
        font-family: $accent_font;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        color: $color_text;

        @media (max-width: 768px) {
            font-size: 14px;
            line-height: 18px;
        }

        &::before {
            content: '';
            display: block;
            width: 16px;
            height: 16px;
            margin-top: 2px;
            margin-right: 12px;
            border-radius: 4px;
            border: 1px solid $main_color;
            transition: 0.35s all;
            flex-shrink: 0;
        }

        &::after {
            position: absolute;
            top: 3px;
            left: 1px;
            content: '';
            display: block;
            width: 14px;
            height: 14px;
            background-image: url('../img/content/check.svg');
            background-size: 14px 14px;
            opacity: 0;
        }
    }

}