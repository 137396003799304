@import "main-bx";

.chat {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 84px);
    padding-top: 80px;
    padding-bottom: 60px;

    @media (max-width: 768px) {
        min-height: calc(100vh - 60px);
        padding-top: 40px;
        padding-bottom: 40px;
    }

    &__container {
        &_center {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: auto;
            margin-bottom: auto;
        }
    }

    &__title {
        margin-bottom: 12px;
        font-size: 40px;
        font-weight: 700;
        line-height: 44px;
        color: $main_color;
        text-align: center;

        @media (max-width: 768px) {
            font-size: 24px;
            line-height: 30px;
        }
    }

    &__text {
        margin-bottom: 40px;
        font-size: 24px;
        font-weight: 500;
        line-height: 26px;
        color: $color_text;
        text-align: center;

        @media (max-width: 768px) {
            font-size: 18px;
            line-height: 24px;
        }
    }

    &__block {
        padding-top: 20px;
        padding-bottom: 28px;
        background-color: $chat_background_color;
        border-radius: $border_radius;

        @media (max-width: 768px) {
            padding-top: 5px;
        }
    }

    &__top-block {
        position: sticky;
        top: 0;
        display: flex;
        height: 102px;
        align-items: center;
        margin-bottom: 12px;
        padding: 20px 40px;
        z-index: 50;

        @media (max-width: 768px) {
            height: 65px;
            padding: 10px 10px;
        }

        @media (max-width: 425px) {
            height: 65px;
        }

        @media (max-width: 345px) {
            height: 95px;
        }

        &_online {
            .chat__img-block {
                position: relative;

                @media (max-width: 768px) {
                    width: 45px;
                    height: 45px;
                    margin-right: 15px;
                }

                &::before {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    content: '';
                    display: flex;
                    width: 20px;
                    height: 20px;
                    background-color: #8ADA09;
                    border: 4px solid #FFFFFF;
                    border-radius: 50%;
                }
            }
        }

        &_sticky {
            background-color: $header_sticky_color;
            box-shadow: $header_sticky_shadow;

            .chat__img-block {
                width: 62px;
                height: 62px;
                margin-right: 20px;
            }

            .chat__name {
                color: $header_sticky_text_color;
            }

            .chat__job-title {
                width: auto;
                color: $header_sticky_text_color;
            }

            .chat__button-tel {
                display: block;
                opacity: 1;
                color: $header_sticky_button_text_color;

                &:hover {
                    color: $main_color;
                }
            }
        }
    }

    &__button-tel {
        margin-left: auto;
        display: none;
        opacity: 0;

        @media (max-width: 768px) {
            flex-shrink: 0;
            height: 40px;
            padding: 10px 10px;
            font-size: 10px;
        }
    }

    &__img-block {
        flex-shrink: 0;
        width: 80px;
        height: 80px;
        margin-right: 24px;

        @media (max-width: 768px) {
            width: 45px;
            height: 45px;
            margin-right: 15px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: 0.35s all;
        }
    }

    &__text-block {
        display: flex;
        flex-direction: column;
        margin-right: 5px;
    }

    &__name {
        margin-bottom: 4px;
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        color: $color_text;

        @media (max-width: 768px) {
            font-size: 13px;
            line-height: 20px;
        }
    }

    &__job-title {
        width: max-content;
        font-size: 13px;
        font-weight: 400;
        line-height: 17px;
        color: $color_text;

        @media (max-width: 768px) {
            font-size: 10px;
            line-height: 10px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        padding: 0 40px;

        @media (max-width: 768px) {
            padding: 0 15px;
        }
    }

    &__message {
        align-self: flex-start;
        max-width: fit-content;
        margin-right: 30px;
        margin-bottom: 12px;
        padding: 20px;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        border-radius: 0 $border_radius $border_radius $border_radius;
        color: $color_text;
        background-color: $color_white;

        @media (max-width: 768px) {
            margin-bottom: 10px;
            padding: 10px;
            font-size: 14px;
            line-height: 18px;
        }
    }

    &__answer {
        max-width: 400px;
        align-self: flex-end;
        margin-bottom: 12px;
        padding: 20px;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        color: $color_white;
        background-color: $answer_messages_color;
        border-radius: $border_radius $border_radius 0px $border_radius;

        @media (max-width: 768px) {
            margin-bottom: 10px;
            padding: 10px;
            font-size: 14px;
            line-height: 18px;
        }
    }

    &__label {
        display: block;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__button {
        margin-bottom: 12px;
    }

    &__input-block {
        margin-bottom: 12px;
    }

    &__agree-text {
        color: initial;
        opacity: 50%;

        a {
            color: $main_color;

            &:hover {
                text-decoration: underline;
            }
        }

        @media (max-width: 768px) {
            font-size: 14px;
        }
    }

    &__info-block {
        position: fixed;
        left: 60px;
        bottom: 115px;
        max-width: 307px;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        color: $color_text;

        @media (max-width: 1650px) {
            position: static;
            margin: 40px auto 0;
            padding-left: 20px;
            padding-right: 20px;
            max-width: 700px;
            font-size: 14px;
            line-height: 18px;
        }
    }

    &__model-block {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 18px 16px 20px;
        border-radius: $border_radius;
        background-color: $color_white;
        border: 2px solid transparent;
        //min-height: 310px;
        transition: 0.35s all;

        &::before {
            position: absolute;
            bottom: -2px;
            right: -2px;
            content: '';
            display: block;
            width: 28px;
            height: 28px;
            background-image: url('../img/content/check2.svg');
            opacity: 0;
            transition: 0.35s all;
        }

        @media (max-width: 768px) {
            min-height: initial;
        }
    }

    &__model-img {
        height: auto;
        max-height: 130px;
        margin-bottom: 16px;
        line-height: 0;
        border-radius: $border_radius;
        overflow: hidden;

        @media (max-width: 500px) {
            height: auto;
        }

        img {
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }

    &__model {
        //min-height: 45px;
        margin-bottom: 4px;
        padding-left: 8px;
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        color: $color_text;

        @media (max-width: 768px) {
            min-height: initial;
            font-size: 14px;
            line-height: 18px;
        }
    }

    &__desc {
        padding-left: 8px;
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        margin-bottom: 5px;

        @media (max-width: 768px) {
            font-size: 14px;
            line-height: 18px;

            br {
                display: none;
            }
        }

        &.is-blue {
            color: $color_blue;
        }

        &.is-orange {
            color: $color_orange;
        }

        &.is-red {
            color: $color_red;
        }
    }

    &__info {
        position: absolute;
        top: -2px;
        left: -2px;
        right: -2px;
        min-height: 20px;
        padding: 2px 5px;
        font-size: 11px;
        font-weight: 700;
        line-height: 16px;
        color: $color_white;
        text-align: center;
        background-color: $benefit_cards_color;
        border-radius: $border_radius $border_radius 0 0;
    }

    &__model-list {
        display: flex;
        flex-wrap: wrap;
        margin-left: -10px;
        margin-right: -10px;
    }

    &__model-item {
        width: $cards_on_line;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 12px;
        cursor: pointer;

        @media (max-width: 768px) {
            width: calc(100% / 2 - 20px);
        }

        @media (max-width: 500px) {
            width: $cards_on_line_mobile;
        }

        input {
            display: none;

            &:checked {
                &+.chat__model-block {
                    border-color: $message_color_border;

                    &::before {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &__color-list {
        display: flex;
        flex-wrap: wrap;
        max-width: 510px;
        margin-bottom: 6px;
    }

    &__color-item {
        margin-right: 6px;
        margin-bottom: 6px;
        cursor: pointer;

        input {
            display: none;

            &:checked {
                &+.chat__color-wrap {
                    background-color: $main_color;

                    .chat__color-text {
                        color: $color_white;
                    }
                }
            }
        }
    }

    &__color-wrap {
        display: flex;
        align-items: center;
        padding: 13px 24px 12px 16px;
        background-color: $color_white;
        border-radius: $border_radius_colors;
        transition: 0.35s all;

        &_other {
            padding-left: 24px;
        }
    }

    &__color {
        display: flex;
        width: 25px;
        height: 25px;
        margin-right: 10px;
        border-radius: $border_radius;
        border: 0.1px solid $color_white;
    }

    &__color-text {
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        color: $color_text;
        transition: 0.35s all;

        @media (max-width: 768px) {
            font-size: 14px;
            line-height: 18px;
        }
    }

    &__message-block {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 50px 50px 70px;
        background-color: $color_white;
        box-shadow: 0px 0px 15px 0px #00000014;
        border-radius: $border_radius;

        @media (max-width: 768px) {
            padding: 30px 15px 50px;
        }

        svg {
            margin-bottom: 24px;
        }
    }

    &__policy-text {
        padding: 50px 50px 70px;
        background-color: $color_white;
        box-shadow: 0px 0px 15px 0px #00000014;
        border-radius: $border_radius;
        line-height: 150%;

        @media (max-width: 768px) {
            padding: 30px 15px 50px;
            font-size: 14px;
        }

        h3 {
            margin-bottom: 20px;
        }

        p {
            margin-bottom: 15px;
        }
    }

    &__message-title {
        margin-bottom: 16px;
        font-size: 32px;
        font-weight: 700;
        line-height: 35px;
        color: $main_color;
        text-align: center;

        @media (max-width: 768px) {
            font-size: 28px;
            line-height: 34px;
        }
    }

    &__message-text {
        margin-bottom: 40px;
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
        color: $color_text;
        text-align: center;

        @media (max-width: 768px) {
            margin-bottom: 30px;
        }
    }
}