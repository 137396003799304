$font: 'NeoSansCyr';
$main_color: #006BB1;
$body_background_color: $color_white;
$header_background_color: $color_white;
$header_text_color: #006BB1;
$header_shadow: 0px 0px 15px 0px #00000014;
$header_sticky_color: #fafafa;
$header_sticky_shadow: 0px 0px 15px 0px #00000014;
$header_sticky_text_color: $color_text;
$header_sticky_button_text_color: $color_white;
$chat_background_color: $color_gray;
$benefit_cards_color: #006BB1;
$answer_messages_color: #006BB1;
$message_color_border: $main_color;
$border_radius: 10px;
$border_radius_colors: 50px;

$cards_on_line: calc(100% / 4 - 20px);
$cards_on_line_mobile: calc(100% / 2 - 20px);