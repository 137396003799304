$color_text: #181b1d;
$color_black: #000000;
$color_white: #ffffff;
$color_gray: #f1f1f1;
$color_orange: #f68d2e;
$color_red: #ca0100;
$color_blue: #0a3a6a;

//@import "./../brands/changan";
//@import "./../brands/chery";
//@import "./../brands/exeed";
@import "./../brands/geely";

$accent_font: $font, sans-serif;

$transition: 0.25s;

$body_width: 1920px;

$breaks: (
        desktop_lg: 1600px,
        desktop: 1440px,
        laptop: 1380px,
        tablet: 1100px,
        tablet_md: 1024px,
        mobile: 768px,
        mobile_sm: 575px,
        mobile_xs: 479px,
        mobile_xxs: 375px,
);

$container: (
        desktop_lg: 1400px,
        desktop: 1140px,
        laptop: 1024px,
        tablet: 768px,
        mobile: 345px,
        mobile_xs: 280px,
);