@import "main-bx";

.input {
    display: block;
    width: 300px;
    height: 50px;
    padding: 17px 15px;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    color: $color_text;
    background-color: $color_white;
    border-radius: $border_radius;

    @media (max-width: 768px) {
        width: 100%;
        height: 44px;
        padding: 13px 15px;
        font-size: 14px;
        line-height: 18px;
    }

    &::placeholder {
        color: #A3A5A6;
    }
}