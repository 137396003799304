@import "main-bx";

.header {
    background-color: $header_background_color;
    box-shadow: $header_shadow;

    &__container {
        display: flex;
        align-items: center;
        height: 84px;

        @media (max-width: 768px) {
            height: 60px;
        }
    }

    &__logo {
        line-height: 0;
        display: flex;
        align-items: center;
        justify-content: left;

        img {
            margin-right: 20px;
            height: 35px;
        }

        @media (max-width: 768px) {
            img {
                margin-right: 10px;
                height: 20px;
                max-width: 180px;
            }
        }
    }

    &__logo-changan {
        line-height: 0;
        display: flex;
        align-items: center;
        justify-content: left;

        img {
            margin-right: 20px;
            width: auto;
            height: 40px;
        }

        @media (max-width: 768px) {
            img {
                margin-right: 10px;
                height: 30px;
            }
        }
    }

    &__logo-chery {
        line-height: 0;
        display: flex;
        align-items: center;
        justify-content: left;

        img {
            margin-right: 20px;
            width: auto;
            height: 25px;
        }

        @media (max-width: 768px) {
            img {
                margin-right: 10px;
                height: 15px;
            }
        }
    }

    &__dealer {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.025em;
        color: $header_text_color;

        @media (max-width: 768px) {
            font-size: 10px;
            line-height: 10px;
        }

        @media (max-width: 410px) {
            font-size: 9px;
            max-width: 130px;
        }
    }

    &__dealer-name {
        font-weight: 600;
    }

    &__right-block {
        margin-left: auto;
        text-align: right;

        @media (max-width: 768px) {
            margin-top: -3px;
        }
    }

    &__address {
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0.025em;
        color: $header_text_color;
        text-transform: uppercase;

        @media (max-width: 900px) {
            display: none;
        }
    }

    &__phone {
        margin-left: 32px;
        font-size: 14px;
        font-weight: 700;
        line-height: 14px;
        letter-spacing: 0.025em;
        color: $header_text_color;

        @media (max-width: 768px) {
            margin-left: 0;
            font-size: 12px;
            line-height: 12px;
        }

        @media (max-width: 410px) {
            font-size: 9px;
        }
    }
}