/* CHANGAN */
@font-face {
    font-family: 'ChangAnunitype';
    src: url('../fonts/ChangAnunitype/ChangAnunitype-Light.woff2') format('woff2'),
        url('../fonts/ChangAnunitype/ChangAnunitype-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ChangAnunitype';
    src: url('../fonts/ChangAnunitype/ChangAnunitype-Regular.woff2') format('woff2'),
        url('../fonts/ChangAnunitype/ChangAnunitype-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ChangAnunitype';
    src: url('../fonts/ChangAnunitype/ChangAnunitype-Bold.woff2') format('woff2'),
        url('../fonts/ChangAnunitype/ChangAnunitype-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TacticRound';
    src: url('../fonts/TacticRound/TacticRound-Reg.woff2') format('woff2'),
        url('../fonts/TacticRound/TacticRound-Reg.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TacticRoundExd';
    src: url('../fonts/TacticRound/TacticRoundExd-Med.woff2') format('woff2'),
        url('../fonts/TacticRound/TacticRoundExd-Med.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TacticRoundExtExd';
    src: url('../fonts/TacticRound/TacticRoundExtExd-Bld.woff2') format('woff2'),
        url('../fonts/TacticRound/TacticRoundExtExd-Bld.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

/* CHERY */
@font-face {
    font-family: 'HarmonyOS';
    src: url('../fonts/HarmonyOS_Sans_SC/HarmonyOS_Sans_SC_Thin.woff2') format('woff2'),
        url('../fonts/HarmonyOS_Sans_SC/HarmonyOS_Sans_SC_Thin.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HarmonyOS';
    src: url('../fonts/HarmonyOS_Sans_SC/HarmonyOS_Sans_SC_Light.woff2') format('woff2'),
        url('../fonts/HarmonyOS_Sans_SC/HarmonyOS_Sans_SC_Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HarmonyOS';
    src: url('../fonts/HarmonyOS_Sans_SC/HarmonyOS_Sans_SC_Regular.woff2') format('woff2'),
        url('../fonts/HarmonyOS_Sans_SC/HarmonyOS_Sans_SC_Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HarmonyOS';
    src: url('../fonts/HarmonyOS_Sans_SC/HarmonyOS_Sans_SC_Medium.woff2') format('woff2'),
        url('../fonts/HarmonyOS_Sans_SC/HarmonyOS_Sans_SC_Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HarmonyOS';
    src: url('../fonts/HarmonyOS_Sans_SC/HarmonyOS_Sans_SC_Bold.woff2') format('woff2'),
        url('../fonts/HarmonyOS_Sans_SC/HarmonyOS_Sans_SC_Bold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HarmonyOS';
    src: url('../fonts/HarmonyOS_Sans_SC/HarmonyOS_Sans_SC_Black.woff2') format('woff2'),
        url('../fonts/HarmonyOS_Sans_SC/HarmonyOS_Sans_SC_Black.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

/* EXEED */
@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat/Montserrat-Regular.woff2') format('woff2'),
        url('../fonts/Montserrat/Montserrat-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat/Montserrat-Medium.woff2') format('woff2'),
        url('../fonts/Montserrat/Montserrat-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat/Montserrat-SemiBold.woff2') format('woff2'),
        url('../fonts/Montserrat/Montserrat-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat/Montserrat-Bold.woff2') format('woff2'),
        url('../fonts/Montserrat/Montserrat-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

/* GEELY */
@font-face {
    font-family: 'NeoSansCyr';
    src: url('../fonts/NeoSansCyr/NeoSansCyr-Light.woff2') format('woff2'),
        url('../fonts/NeoSansCyr/NeoSansCyr-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'NeoSansCyr';
    src: url('../fonts/NeoSansCyr/NeoSansCyr-Regular.woff2') format('woff2'),
        url('../fonts/NeoSansCyr/NeoSansCyr-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'NeoSansCyr';
    src: url('../fonts/NeoSansCyr/NeoSansCyr-Medium.woff2') format('woff2'),
        url('../fonts/NeoSansCyr/NeoSansCyr-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}