@import "main-bx";

html {
    scroll-padding-top: 8rem;
    scroll-behavior: smooth;
    height: 100%;

    &.open-menu {
        overflow: hidden;
    }
}

*,
*::before,
*::after {
    font-kerning: none;
    font-feature-settings: "pnum" on, "lnum" on;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
p {
    margin: 0;
    padding: 0;
}

.visually-hidden {
    position: absolute;

    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;

    white-space: nowrap;
    clip: rect(0 0 0 0);
    overflow: hidden;
}

.container {
    width: 100%;
    max-width: 1160px;
    margin: 0 auto;
    padding: 0 20px;

    @include below(768px) {}

    @include below(600px) {
        padding: 0 10px;
    }
}

.container-sm {
    width: 100%;
    max-width: 880px;
    margin: 0 auto;
    padding: 0 20px;

    @include below(768px) {}

    @include below(600px) {
        padding: 0 10px;
    }
}

.desktop {
    display: flex !important;

    @include m-tablet() {
        display: none !important;
    }
}

.mobile {
    display: none !important;

    @include m-tablet() {
        display: flex !important;
    }
}

body {
    display: flex;
    flex-direction: column;
    position: relative;
    font-family: $accent_font !important;
    font-size: 16px;
    background-color: $body_background_color;
    width: 100%;
    color: $color_text;
    scroll-behavior: smooth;
    min-height: 100%;

    &.open-menu,
    &.open-modal {
        overflow: hidden;
    }
}

@keyframes fadeIn1 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 0.7;
    }
}

ul {
    list-style-type: none;
}

a {
    text-decoration: none;
    // transition: $trns;
}

.main {
    position: relative;
    flex-grow: 1;

    &_gray {
        background-color: $color_gray;
    }

    &.has-banner {
        .chat {
            margin-top: 0;
        }
    }
}

// .title {
//     font-size: 60px;
//     font-weight: 700;
//     line-height: 84px;
//     color: $color_text;
//     text-transform: uppercase;

//     span {
//         color: $color_light-green;
//     }

//     @include m-phone() {
//         font-size: 30px;
//         line-height: 42px;
//     }

//     &_center {
//         text-align: center;
//     }
// }

// .title-m {
//     font-size: 50px;
//     font-weight: 700;
//     line-height: 70px;
//     color: $color_text;
//     text-transform: uppercase;

//     @media (max-width: 1024px) {
//         font-size: 60px;
//         line-height: 78px;        
//     }

//     @include m-phone() {
//         font-size: 30px;
//         line-height: 42px;
//     }
// }

// .title-sm {
//     font-size: 50px;
//     font-weight: 700;
//     line-height: 60px;
//     color: $color_text;
//     text-transform: uppercase;

//     @media (max-width: 1024px) {
//         font-size: 50px;
//         line-height: 60px;        
//     }

//     @include m-phone() {
//         font-size: 30px;
//         line-height: 42px;
//     }
// }

:focus {
    outline-style: none;
    outline-width: 0 !important;
    outline-color: none !important;
}

input,
button,
textarea,
fieldset {
    background: transparent;
    border: 0;
    padding: 0;
    font-family: inherit !important;
}

button {
    cursor: pointer;
    transition: all 0.3s linear;
}

img {
    max-width: 100%;
    height: auto;
}

.compensate-for-scrollbar {
    margin: auto !important;
}

.disabled {
    position: relative;
    filter: grayscale(1);

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        content: '';
        z-index: 10;
        background-color: rgba(255, 255, 255, 0.7);
        border-radius: inherit;
    }
}

[disabled] {
    pointer-events: none;
    opacity: 0.5;
}