@import "main-bx";

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  padding: 13px 24px;
  border: 1px solid $main_color;
  border-radius: $border_radius;
  background-color: $main_color;
  font-family: $accent_font;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  color: $color_white;
  transition: all $transition;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 18px;
  }

  &:hover {
    color: $main_color;
    background-color: $color_white;
  }
}