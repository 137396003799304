@mixin h1() {
    margin-top: 20px;
    margin-bottom: 20px;
}

@mixin font-face($name, $path, $weight, $style) {
    @font-face {
        font-family: $name;
        src: url($path + ".woff2") format("woff2"),
            url($path + ".woff") format("woff");
        font-weight: $weight;
        font-style: $style;
        font-display: swap;
    }
}

@mixin below($width) {
    @media (max-width: $width) {
        @content;
    }
}

@mixin above($width) {
    @media (min-width: $width) {
        @content;
    }
}

@mixin below_and_above($max, $min) {
    @media (max-width: $max) and (min-width: $min) {
        @content;
    }
}

@mixin d-small() {
    @media (max-width: 1200px) {
        @content
    }
}

@mixin m-tablet() {
    @media (max-width: 1023px) {
        @content
    }
}

@mixin m-phone() {
    @media (max-width: 767px) {
        @content
    }
}

@mixin fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}



@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-font-size);
    $u4: unit($max-font-size);

    @if $u1 ==$u2 and $u1 ==$u3 and $u1 ==$u4 {
        & {
            font-size: $min-font-size;

            @media screen and (min-width: $min-vw) {
                font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }

            @media screen and (min-width: $max-vw) {
                font-size: $max-font-size;
            }
        }
    }
}